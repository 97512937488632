<template>
  <div>
    <shop-head currentPageName="LOGIN"></shop-head>
    <!-- 面包屑 -->
    <breadcrumb pageName="Login"></breadcrumb>
    <div class="container login-box">
      <el-row type="flex" justify="center">
        <el-col :md="12">
          <div class="login-form">
            <div class="login-title">Login</div>
            <el-form
              ref="form"
              :model="form"
              :rules="rules"
              label-position="top"
            >
              <el-form-item label="Email Address" prop="emailAddress">
                <el-input
                  v-model="form.emailAddress"
                  placeholder="Email Address"
                ></el-input>
              </el-form-item>
              <el-form-item label="Password" prop="password">
                <el-input
                  v-model="form.password"
                  type="password"
                  placeholder="Password"
                ></el-input>
              </el-form-item>
              <el-row>
                <el-col :span="12">
                  <el-checkbox v-model="isRemember">Remember Me</el-checkbox>
                </el-col>
                <el-col :span="12" class="forget-text">
                  <span @click="gotoResetPassword">Forgotten pasward?</span>
                </el-col>
              </el-row>
              <div @click="doLogin" class="login-btn">LOGIN</div>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </div>
    <common-footer></common-footer>
  </div>
</template>

<script>
import shopHead from "../../components/shop/head";
import commonFooter from "../../components/common/footer";
import breadcrumb from "../../components/common/breadcrumb";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    shopHead,
    commonFooter,
    breadcrumb,
  },
  data() {
    return {
      userName: "",
      isRemember: false,
      form: {
        emailAddress: "",
        password: "",
      },
      rules: {
        emailAddress: [{ required: true, message: " ", trigger: "blur" }],
        password: [{ required: true, message: " ", trigger: "blur" }],
      },
    };
  },
  created() {
    //读取记住账号、密码信息
    let account = window.localStorage.getItem("name");
    let password = window.localStorage.getItem("key");
    if (account && password) {
      this.$set(this.form, "emailAddress", account);
      this.$set(this.form, "password", atob(password));
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    ...mapActions(["saveUserInfo"]),
    /** 登录 */
    doLogin() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$axios
            .post("/foreign/login/loginByHw", {
              email: this.form.emailAddress,
              password: this.form.password,
            })
            .then((res) => {
              if (res.successful) {
                //保存token
                window.sessionStorage.setItem(
                  "accessToken",
                  res.result.accessToken
                );
                //用户名
                window.sessionStorage.setItem(
                  "userName",
                  res.result.companyName
                );
                if (
                  res.result.agencyLevel !== null &&
                  res.result.agencyLevel !== undefined &&
                  res.result.agencyLevel !== undefined
                ) {
                  //代理级别
                  window.sessionStorage.setItem(
                    "agencyLevel",
                    res.result.agencyLevel
                  );
                }
                if (this.isRemember) {
                  window.localStorage.setItem("name", this.form.emailAddress);
                  window.localStorage.setItem("key", btoa(this.form.password));
                }
                this.redirectLocation();
                /* this.$router.push({
                  path: "/home",
                }); */
                /* 换账号后为了重新加载IM */
                if (
                  this.userInfo &&
                  this.userInfo.id &&
                  this.userInfo.id !== res.result.id
                ) {
                  window.location.reload();
                } else {
                  this.saveUserInfo();
                }
              } else {
                this.$message.error(res.tips);
              }
            });
        }
      });
    },
    /** 忘记密码 */
    gotoResetPassword() {
      this.$router.push({
        path: "/resetPassword",
      });
    },
    /**
     * 重定向跳转
     */
    redirectLocation() {
      let query = this.$route.query;
      let redirectUrl = query.redirectUrl
        ? decodeURIComponent(query.redirectUrl)
        : "";
      if (redirectUrl) {
        window.location.replace(redirectUrl);
      } else {
        this.$router.push({
          path: "/home",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-box {
  padding: 60px 15px;
  .login-form {
    background-color: #ffffff;
    padding: 30px;
    box-shadow: 1px 1px 5px 5px rgb(0, 0, 0, 0.1);
    .login-title {
      font-size: 20px;
      line-height: 23px;
      text-transform: capitalize;
      font-weight: 700;
      margin-bottom: 0;
      padding-bottom: 20px;
    }
    ::v-deep .el-form-item {
      margin-bottom: 15px;
    }
    ::v-deep .el-form-item__label {
      font-weight: bold;
      line-height: 24px;
      margin-bottom: 12px;
      padding-bottom: 0;
    }
    ::v-deep .el-input__inner {
      border-radius: 0;
      &:focus {
        border-color: #fed100;
      }
    }
    ::v-deep .el-checkbox__input {
      &.is-checked {
        & + .el-checkbox__label {
          color: #fed100;
        }
        .el-checkbox__inner {
          border-color: #fed100;
          background-color: #fed100;
        }
      }
      &.is-focus {
        .el-checkbox__inner {
          border-color: #fed100;
        }
      }
    }
    ::v-deep .el-checkbox__inner {
      &:hover {
        border-color: #fed100;
      }
    }
    ::v-deep .el-checkbox__label {
      font-weight: 400;
      color: #888888;
    }
  }
  .forget-text {
    text-align: right;
    font-weight: 600;
    cursor: pointer;
    &:hover {
      color: #fed100;
    }
  }
  .login-btn {
    width: 140px;
    height: 40px;
    line-height: 40px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    display: block;
    margin-top: 30px;
    border-radius: 0;
    border: 0;
    text-transform: uppercase;
    background-color: #333333;
    cursor: pointer;
    &:hover {
      background-color: #fed100;
    }
  }
}
</style>
